<template>
    <v-card class="mx-auto" max-width="600">
        <v-card-title class="justify-center">
            {{ $t("auth.loginToPassword") }}
        </v-card-title>
        <v-card-text>
            <v-alert
                v-if="success"
                border="left"
                colored-border
                color="success"
                elevation="2"
            >
                {{ $t("auth.forgottenPasswordSuccess") }}
            </v-alert>
            <v-alert
                v-if="error"
                border="left"
                colored-border
                color="error"
                elevation="2"
            >
                {{ $t("auth.forgottenPasswordError") }}
            </v-alert>
            <v-form ref="form" @submit.prevent="onSubmit">
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="form.email"
                            :label="$t('users.user.email')"
                            prepend-icon="mdi-account-outline"
                            :rules="requiredRule"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        :align="
                            $vuetify.breakpoint.smAndUp ? 'right' : 'center'
                        "
                    >
                        <v-btn
                            :loading="isLoading"
                            color="primary"
                            type="submit"
                        >
                            {{ $t("button.submit") }}
                        </v-btn>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        :align="$vuetify.breakpoint.smAndUp ? '' : 'center'"
                    >
                        <v-btn color="primary" :to="{ name: 'Login' }">
                            {{ $t("button.return") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            form: {
                email: "",
            },

            isLoading: false,
            error: false,
            success: false,
        };
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
        }),
    },

    watch: {
        "form.email": function() {
            this.error = false;
            this.success = false;
        },
    },

    mounted() {
        this.$recaptchaLoaded().then(() => {
            this.$recaptchaInstance.showBadge();
        });
    },

    methods: {
        onSubmit() {
            if (this.$refs.form.validate()) {
                this.isLoading = true;
                this.error = false;
                this.success = false;

                this.$recaptchaLoaded().then(() => {
                    this.$recaptcha("login").then((token) => {
                        this.form.captcha = token;

                        this.$store
                            .dispatch("auth/forgottenPassword", this.form)
                            .then(() => {
                                this.success = true;
                                this.isLoading = false;
                            })
                            .catch(() => {
                                this.isLoading = false;
                                this.error = true;
                            });
                    });
                });
            }
        },
    },

    beforeDestroy() {
        this.$recaptchaInstance.hideBadge();
    },
};
</script>
